<template>
  <div>
    <van-popup v-model="fileShow">
      <div class="content">
        <div class="boss">
          <div class="video">
            <img :src="pigDetal.image" alt="" />
          </div>
          <div class="info">
            <p class="name">
              {{ pigDetal.nickname }}
              <span class="uplaodImg" @click="nameshow = true">
                <img src="../../../assets/img/upload.png" alt="" />
              </span>
            </p>
            <ul class="ulInfo">
              <li>
                领养编号： <span class="left">{{ pigDetal.pig_num }}</span>
              </li>
              <li>
                当前猪龄：<span class="left">{{ pigDetal.month }} 月</span>
              </li>
              <!-- <li>
                当前重量：<span class="left">{{ pigDetal.weight }} KG</span>
              </li> -->
              <li>
                领养日期：<span class="left">{{ pigDetal.adopt_time }}</span>
              </li>
            </ul>
            <div class="certificate">
              <van-tabs
                v-model="active"
                title-active-color="#F9750C"
                title-inactive-color="#945614"
              >
                <van-tab title="防疫记录">
                  <!-- <div class="infoText" v-if="pigDetal.prevent">
                    <div class="content">
                      <p class="time">2022-10-1</p>
                      <p class="medicine">病毒</p>
                    </div>
                  </div> -->
                  <div class="infoText" v-if="pigDetal.prevent">
                    <div
                      class="content"
                      v-for="(item, index) in pigDetal.prevent"
                      :key="index"
                    >
                      <p class="time">{{ item.add_time }}</p>
                      <p class="medicine">{{ item.prevention }}</p>
                    </div>
                  </div>
                  <div v-else>
                    <div class="none">暂无数据</div>
                  </div>
                </van-tab>
                <van-tab title="喂养记录">
                  <div v-if="feedList.length">
                    <div
                      class="div-content"
                      v-for="item in feedList"
                      :key="item[0].date"
                    >
                      <div>
                        <p class="time">{{ item[0].date }}</p>
                        <div class="infos" v-for="dd in item" :key="dd.id">
                          <p class="info_time">{{ dd.time }}</p>
                          <div class="displays">
                            <p
                              class="info_text"
                              v-for="val in dd.feed"
                              :key="val.id"
                            >
                              <span
                                v-if="
                                  val.cate_id != 2 &&
                                  val.cate_id != 1 &&
                                  val.cate_id != 3
                                "
                              >
                                {{ val.name + "：" + val.weight + "克、" }}
                              </span>
                              <span v-if="val.cate_id == 1">
                                {{ val.name + "：" + val.weight + "份、" }}
                              </span>
                              <span v-if="val.cate_id == 2">
                                {{ val.name + "、" }}
                              </span>
                              <span v-if="val.cate_id == 3">
                                {{ val.name }}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="none">暂无数据</div>
                  </div>
                </van-tab>
              </van-tabs>
              <p
                class="more"
                v-if="active == 1 && !isNull"
                @click="$router.push('/more')"
              >
                查看更多
              </p>
            </div>
          </div>
          <div class="close" @click="close">
            <img src="../../../assets/img/close.png" alt="" />
          </div>
        </div>
      </div>
    </van-popup>
    <update-name
      :nameshow="nameshow"
      @changeNameShow="changeNameShow"
    ></update-name>
  </div>
</template>

<script>
import { pigFiles, record } from "../../../common/api";
import updateName from "./updateName.vue";
export default {
  components: { updateName },
  props: {
    fileShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active: 1,
      pigDetal: {},
      feedList: [],
      isNull: false,
      nameshow: false,
    };
  },
  methods: {
    clickInit() {
      this.initDetail();
      this.recordJl();
    },
    changeNameShow(val) {
      this.nameshow = val;
    },
    //获取猪崽档案
    async initDetail() {
      let data = {
        access_id: this.$store.state.access_id,
        p_id: this.$store.state.p_id,
      };
      let res = await pigFiles(data);
      if (res.data.code != 200) return;
      console.log(res.data.data, "档案");
      this.pigDetal = res.data.data;
      this.$store.commit("SET_NICKNAME", res.data.data.nickname);
    },

    //喂养记录
    async recordJl() {
      let data = {
        access_id: this.$store.state.access_id,
        ls_id: this.$store.state.p_id,
        page: 1,
        days: 3,
      };
      let res = await record(data);
      console.log(res, "喂养记录");
      if (res.data.code == "4004") {
        console.log(1);
        this.isNull = true;
        return;
      }
      if (res.data.code != "200") return;
      this.feedList = res.data.data;
      console.log(this.feedList, "this.feedList");
    },
    close() {
      this.$emit("closeDa", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.van-popup {
  width: 100vw;
  height: 100vh;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    height: 17rem;
    width: 8.9rem;
    background: url("../../../assets/img/feed/file.png");
    background-position: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    justify-content: center;
    .boss {
      width: 7.8rem;
      height: 14.3rem;
      margin-top: 1.78rem;
      display: flex;
      flex-direction: column;
      // align-items: center;
      .video {
        width: 7.45rem;
        height: 4.6rem;
        margin: 0.2rem;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .info {
        display: flex;
        // justify-content: center;
        flex-direction: column;
        align-items: center;
        .name {
          width: 100%;
          font-size: 0.5rem;
          font-weight: bold;
          color: #945614;
          padding-left: 0.9rem;
        }
        .ulInfo {
          width: 100%;
          font-size: 0.4rem;
          font-weight: 500;
          color: #945614;
          padding-left: 1rem;
          li {
            margin: 0.2rem 0;
          }
          .left {
            margin-left: 0.5rem;
            font-weight: 600;
          }
        }
        .certificate {
          width: 7rem;
          height: 4.2rem;
          border-radius: 0.2rem;
          background: #ffc693;
          margin-top: 0.2rem;
          position: relative;
          .more {
            margin-top: 0.2rem;
            font-size: 0.4rem;
            font-weight: 500;
            color: #945614;
            text-align: center;
            position: absolute;
            bottom: -0.8rem;
            left: 2.7rem;
          }
          .van-tabs {
            /deep/ .van-tab__pane {
              height: 3.2rem;
              overflow: hidden;
              overflow-y: scroll;
            }

            // .infoText {
            //   // height: 2.75rem;
            //   padding: 0 0.2rem;
            //   overflow: hidden;
            //   overflow-y: scroll;
            //   // margin-top: 0.1rem;
            //   display: flex;
            //   .content {
            //     height: 0.88rem;
            //     background: #fbe5ba;
            //     border-radius: 0.25rem;
            //     padding: 0.2rem 0.3rem;
            //     margin: 0.15rem 0;
            //     display: flex;
            //     flex-direction: column;
            //     .time {
            //       font-size: 0.3rem;
            //       color: #945614;
            //       line-height: 1;
            //       font-weight: 400;
            //       margin-top: 0.1rem;
            //     }
            //     .medicine {
            //       font-size: 0.35rem;
            //       color: #945614;
            //       font-weight: 600;
            //       line-height: 2;
            //     }
            //   }
            // }
            .infoText {
              width: 6rem;
              height: 3.5rem;
              padding: 0 0.2rem;
              overflow: hidden;
              overflow-y: scroll;
              display: flex;
              flex-direction: column;
              .content {
                width: 6rem;
                background: #fbecd3;
                border-radius: 0.05rem;
                box-sizing: border-box;
                padding: 0 0.3rem;
                margin: 0.15rem 0;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                .time {
                  font-size: 0.3rem;
                  color: #945614;
                  line-height: 1;
                  font-weight: 400;
                  padding: 0.2rem 0 0.2rem;
                }
                .medicine {
                  font-size: 0.35rem;
                  color: #945614;
                  font-weight: 600;
                  line-height: 1;
                  padding-bottom: 0.2rem;
                }
              }
            }
            /deep/ .van-tab__text {
              height: 0.8rem;
              font-size: 0.4rem;
              line-height: 0.8rem;
            }

            /deep/ .van-tabs__wrap {
              height: 0.9rem;
              border-bottom: 1px dashed #945614;
            }
            /deep/ .van-tabs__nav {
              background-color: #ffc693;
            }
            /deep/ .van-tabs__line {
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 0.2rem;
              border-color: transparent transparent #d79f6d transparent;
              background: none;
            }
          }

          .record {
            height: 0.6rem;
            border-bottom: 1px dashed #d79f6d;
            box-sizing: border-box;
            margin: 0 0.2rem;
            padding: 0 0.2rem;
            font-size: 0.34rem;
            line-height: 0.6rem;
            font-weight: 500;
            color: #f9750c;
          }
        }
      }
    }
  }
}
.close {
  position: absolute;
  top: 0.65rem;
  right: -0.2rem;
  img {
    width: 1rem;
    height: auto;
  }
}
::-webkit-scrollbar {
  display: none;
}
.div-content:last-child {
  padding-bottom: 0.4rem;
}
.div-content {
  .time {
    width: 100%;
    font-size: 0.4rem;
    font-weight: 600;
    color: #945614;
    padding: 0.2rem 0 0 0.2rem;
  }
  .infos {
    width: 6.5rem;
    background: #000;
    margin: auto;
    margin-top: 0.2rem;
    background: rgba(#945614, 0.1);
    border-radius: 0.2rem;
    .displays {
      display: flex;
      flex-wrap: wrap;
    }
    .info_time {
      font-size: 0.35rem;
      color: #945614;
      padding-left: 0.3rem;
      padding-top: 0.15rem;
    }
    .info_text {
      font-size: 0.4rem;
      color: #945614;
      padding-left: 0.3rem;
      padding-top: 0.1rem;
      font-weight: 600;
    }
    .info_text:last-child {
      padding-bottom: 0.2rem;
    }
  }
}
.none {
  width: 100%;
  height: 3.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.4rem;
  color: #945614;
}
.uplaodImg {
  height: 0.5rem;
  img {
    width: 0.5rem;
    height: 0.5rem;
    margin-left: 0.2rem;
  }
}
</style>
