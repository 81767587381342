<template>
  <div v-show="isShow">
    <v-touch v-on:swipeleft="onSwipeLeft" v-on:swiperight="onSwipeRight">
      <div class="content">
        <van-nav-bar :title="$store.state.nickname">
          <template #right>
            <img
              src="../../assets/img/close.png"
              class="navImg"
              @click="close"
            />
          </template>
        </van-nav-bar>
        <div class="bar">
          <img src="../../assets/img/feed/tixing.png" alt="" />
          <van-notice-bar background="none">
            {{
              '当前猪龄' +
              pigDetail.week +
              '周，建议每次喂食' +
              pigDetail.feedGuide +
              '克，杜绝浪费'
            }}
          </van-notice-bar>
        </div>
        <div class="info">
          <div class="video">
            <div class="videos" id="video-container"></div>
          </div>
          <div class="btn">
            <div
              class="isFeed"
              v-if="
                pigDetail.is_feed == 0 &&
                pigDetail.feedType > 0 &&
                pigDetail.auto_feed == 0
              "
            >
              <!-- 喂食 -->
              <img src="../../assets/img/feed/weishi.png" alt="" @click="Ws" />
            </div>
            <div
              class="yijing"
              v-if="
                pigDetail.auto_feed == 0 &&
                pigDetail.is_feed == 0 &&
                pigDetail.nextFeed > 0
              "
            >
              <div v-for="(val, key) in pigDetail.eat_type" :key="key">
                <p v-if="pigDetail.nextFeed == key">
                  {{ val.start }}~{{ val.end }}点喂食
                </p>
              </div>
            </div>
            <div
              class="yijing"
              v-if="pigDetail.is_feed == 1 && pigDetail.auto_feed == 0"
            >
              <p class="readfeed">已喂食</p>
            </div>
            <div
              class="yijing"
              v-if="
                pigDetail.nextFeed == 0 &&
                pigDetail.feedType == 0 &&
                pigDetail.auto_feed == 0
              "
            >
              <p class="readfeed">已结束</p>
            </div>
            <div class="isFeed" v-if="pigDetail.auto_feed == 1">
              <!-- 托管 -->
              <img src="../../assets/img/feed/tuoguan.png" alt="" />
            </div>
            <!-- 喂养记录 -->
            <div class="jilu" @click="Jl">
              <img src="../../assets/img/feed/jilu.png" alt="" />
            </div>
          </div>
          <div>
            <div class="text">
              <p v-if="pigDetail.auto_feed == 0">
                每日
                <span v-for="(val, key) in pigDetail.eat_type" :key="key">
                  {{ val.start }}~{{ val.end + '点' }}
                </span>
                喂食
              </p>
              <p v-else>剩余餐数(餐)：{{ pigDetail.left_feed }}</p>
            </div>
          </div>
          <!-- 治疗 -->
          <div class="zl" @click="treatShow = true">
            <img src="../../assets/img/feed/zl.png" alt="" />
          </div>
          <!-- 清扫 -->
          <div class="qs">
            <img src="../../assets/img/feed/qs.png" alt="" @click="Qs" />
          </div>
          <!-- 洗澡 -->
          <div class="xz">
            <img src="../../assets/img/feed/xz.png" alt="" @click="Xz" />
          </div>
          <!-- 边框 bk-->
          <div class="bk" style="display: none;">
            <img src="../../assets/img/feed/bk.png" alt="" />
          </div>
          <!-- 档案 da -->
          <div class="bk">
            <img src="../../assets/img/feed/da.png" alt="" @click="Da" />
          </div>
          <!-- 分享  fx-->
          <div class="da">
            <img src="../../assets/img/feed/fx.png" alt="" @click="goShare" />
          </div>
        </div>
        <!-- 动态 -->
        <div class="dongtai" style="display: none;">
          <div class="new">
            <p class="text">最新动态</p>
            <div class="newinfo" style="display: none;">
              <p>您的猪猪生病了，需要治疗！</p>
              <p class="newBtn">去治疗</p>
            </div>
            <div class="friend">
              <img src="../../assets/img/11.jpg" alt="" />
              <p>玉米糁3KG、普通水1份</p>
            </div>
          </div>
        </div>
      </div>
      <van-popup
        v-model="sucessShow"
        class="sucess"
        :close-on-click-overlay="false"
      >
        <img src="../../assets/img/dui.png" alt="" />
        <p class="text">已申请喂食成功</p>
        <p class="textinfo">待饲养员喂食，稍后可在</p>
        <p class="textinfo">消息中查看通知！</p>
        <p class="zhi" @click="Iknowledge">我知道了</p>
      </van-popup>
      <van-popup
        v-model="errorShow"
        class="error"
        :close-on-click-overlay="false"
      >
        <p class="text">温馨提示</p>
        <p class="textinfo">现在还未到喂食时间</p>
        <p class="textinfo">待会再来~</p>
        <p class="zhi" @click="knowledge">我知道了</p>
      </van-popup>
      <van-popup v-model="treatShow" :close-on-click-overlay="false">
        <div class="treat">
          <div class="treatbox">
            <div class="heard">
              <img src="../../assets/img/title.png" alt="" />
            </div>
            <div class="zanwu">暂无疾病信息！</div>
          </div>
          <div class="close" @click="closeTreat">
            <img src="../../assets/img/close.png" alt="" />
          </div>
        </div>
      </van-popup>
      <up-file :fileShow="fileShow" @closeDa="closeDa" ref="Da"></up-file>
      <up-feed :feedShow="feedShow" @closeJl="closeJl" ref="Jl"></up-feed>
      <up-bath :bathShow="bathShow" @closeXz="closeXz" ref="Xz"></up-bath>
      <up-clean :cleanShow="cleanShow" @closeQs="closeQs" ref="Qs"></up-clean>
      <up-ws
        :wsShow="wsShow"
        @closeWs="closeWs"
        @success="success"
        @error="error"
        ref="Ws"
      ></up-ws>
      <van-popup v-model="erroeShow" :overlay="false" class="erroeShow">
        <p class="text">温馨提示</p>
        <p class="textinfo">{{ message }}</p>
      </van-popup>
    </v-touch>
    <van-popup v-model="networkAnomaly" :close-on-click-overlay="false">
      <div class="yichang">
        <p class="title">温馨提示</p>
        <div class="info">
          <p>网络已断开</p>
          <p>请退出重新打开...</p>
        </div>
        <p class="kown" @click="tuichu">我知道了</p>
      </div>
    </van-popup>
  </div>
</template>

<script>
import upFile from './components/file.vue'
import upBath from './components/bath.vue'
import upClean from './components/clean.vue'
import upFeed from './components/feedingRecord.vue'
import upWs from './components/weishi.vue'
import { pigInfo, getToken, ranchData } from '../../common/api'
import { WebView } from '../../common/app'
import EZUIKit from 'ezuikit-js'
import Utils from '../../util/index'
export default {
  components: {
    upFile,
    upFeed,
    upBath,
    upWs,
    upClean,
  },
  data() {
    return {
      name: '小灰灰',
      fileShow: false, //档案弹窗
      sucessShow: false, // 喂养成功弹窗
      errorShow: false, //时间未到
      feedShow: false, //喂养记录弹窗
      bathShow: false, // 洗澡弹窗
      wsShow: false, //喂食弹窗
      treatShow: false, //治疗弹窗
      cleanShow: false, //清扫
      erroeShow: false,
      message: '',
      pigDetail: {},
      pigList: [], // 猪崽集合
      url: '', //地址
      accessToken: '', //萤石云token
      timing: 0,
      networkAnomaly: false,
      timer: null,
      isShow: false,
    }
  },
  created() {
    if (
      /Safari/.test(navigator.userAgent) &&
      !/Chrome/.test(navigator.userAgent)
    ) {
      console.log('Safari')
      if (this.$store.state.access_id) {
        this.isShow = true
      } else {
        this.isShow = false
      }
    } else if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      console.log('进入ios')
      if (this.$store.state.access_id) {
        this.isShow = true
      } else {
        this.isShow = false
      }
    } else {
      console.log('进入安卓')
      this.isShow = true
    }
  },
  mounted() {
    let that = this
    WebView.getPidAndAccessid = this.getPidAndAccessid
    console.log('数据', this.getPidAndAccessid)
    // this.getPidAndAccessid()

    // that.getDetail()
    Utils.$on('getToken', () => {
      that.getToken()
    })
  },
  methods: {
    // 开关
    async ranch() {
      let res = await ranchData()
      console.log(res.data.data.ranch_switch)
      let num = res.data.data.ranch_switch
      if (
        /Safari/.test(navigator.userAgent) &&
        !/Chrome/.test(navigator.userAgent)
      ) {
        console.log('Safari')
        if (num == 0) {
          this.isShow = false
        } else {
          this.isShow = true
        }
      }

      // /(iPhone|iPad|iPod|iOS|Safari)/i.test(navigator.userAgent)
      else if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        if (num == 0) {
          this.isShow = false
        } else {
          this.isShow = true
        }
      } else {
        this.isShow = true
      }
    },
    getPidAndAccessid(pidAndAccessId) {
      console.log('tokn')
      console.log('传过来的数据', pidAndAccessId)
      let all = pidAndAccessId.split('And')
      const accessId = all[1]
      const pid = all[0]
      // const accessId =
      //   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NTA5NjI1NDgsImV4cCI6MTY1MzU1NDU0OCwianRpIjoiNzQ4NDVhMmJlNTBiYmI2MDJhYjA3ZjRkNzg5MDU5MmEifQ.MENYQbic6o3xI0vo3C_t9eh_XEXxXOcbWe6IsTqEzr8'
      // const pid = '161'
      this.$store.commit('SET_ACCESS_ID', accessId)
      this.$store.commit('SET_P_ID', pid)

      this.getDetail()

      setTimeout(() => {
        this.getToken()
      }, 500)
    },
    //左滑动
    onSwipeLeft: function () {
      this.findIndex(this.$store.state.p_id, 1)
      this.changeUrl()
    },
    //右滑动
    onSwipeRight: function () {
      this.findIndex(this.$store.state.p_id, 2)
      this.changeUrl()
    },
    changeUrl() {
      this.player.stop().then(() => {
        this.player.play(this.url)
      })
    },
    findIndex(id, type) {
      const num = this.pigList.findIndex((ele) => {
        return id == ele
      })
      if (type == 2) {
        if (num == 0) {
          this.message = '到头了！'
          this.erroeShow = !this.erroeShow
          setTimeout(() => {
            this.getDetail()
            this.erroeShow = !this.erroeShow
          }, 1000)
          return
        }
        this.$store.commit('SET_P_ID', this.pigList[num - 1])
      } else {
        if (num == this.pigList.length - 1) {
          this.message = '已经没有了！'
          this.erroeShow = !this.erroeShow
          setTimeout(() => {
            this.getDetail()
            this.erroeShow = !this.erroeShow
          }, 1000)
          return
        }
        this.$store.commit('SET_P_ID', this.pigList[num + 1])
      }
      this.message = '切换成功！'
      this.erroeShow = !this.erroeShow
      setTimeout(() => {
        this.getDetail()
        this.erroeShow = !this.erroeShow
      }, 1000)
    },
    Iknowledge() {
      this.getDetail()
      this.sucessShow = !this.sucessShow
    },
    knowledge() {
      this.getDetail()
      this.errorShow = !this.errorShow
    },
    //猪崽详情
    async getDetail() {
      let data = {
        access_id: this.$store.state.access_id,
        ls_id: this.$store.state.p_id,
      }
      let res = await pigInfo(data)
      if (res.data.code != 200) return

      this.pigDetail = res.data.data
      this.pigList = res.data.data.pig_list
      this.url = res.data.data.monitor_url
      this.$store.commit('SET_NICKNAME', res.data.data.nickname)
    },
    // 视频初始化
    async getToken() {
      // 发送 POST 请求
      console.log('进来')
      const res = await getToken()
      if (res.data.code != 200) return
      this.accessToken = res.data.data.accessToken
      console.log(this.accessToken)
      //视频初始化
      console.log('数据999999', res)
      console.log(this.url, 'this.urlthis.url')
      this.player = await new EZUIKit.EZUIKitPlayer({
        autoplay: true, //自动播放
        id: 'video-container', // 视频容器ID
        accessToken: this.accessToken,
        url: this.url,
        audio: 0,
      })
      this.timer = setInterval(() => {
        this.timing += 60000
        if (this.timing >= 600000) {
          this.stopVideo()
          console.log('结束播放')
          this.networkAnomaly = true
          clearInterval(this.timer)
          return
        }
      }, 60000)
    },
    // 停止播放
    stopVideo() {
      this.player.stop()
    },
    // 退出
    tuichu() {
      console.log('点击关闭')
      window.location.href = 'uniwebview://closeFeed?test=closeFeed'
    },
    close() {
      console.log('点击关闭')
      window.location.href = 'uniwebview://closeFeed?test=closeFeed'
    },
    // 去分享
    goShare() {
      window.location.href = 'uniwebview://share?lsId=' + this.$store.state.p_id
    },
    success() {
      this.wsShow = false
      this.sucessShow = !this.sucessShow
    },
    error() {
      this.wsShow = false
      this.errorShow = !this.errorShow
    },
    //关闭治疗
    closeTreat() {
      this.treatShow = false
    },
    // 关闭喂食弹窗
    closeWs(val) {
      this.wsShow = val
    },
    // 关闭档案弹窗
    closeDa(val) {
      this.fileShow = val
    },
    // 关闭洗澡弹窗
    closeXz(val) {
      this.bathShow = val
    },
    // 关闭清扫 弹窗
    closeQs(val) {
      this.cleanShow = val
    },
    closeJl(val) {
      this.feedShow = val
    },
    // 喂食弹窗
    Ws() {
      this.$refs.Ws.clickInit()
      this.wsShow = true
    },
    // 清扫弹窗
    Qs() {
      this.cleanShow = true
      this.$refs.Qs.clickInit()
    },
    // 洗澡弹窗
    Xz() {
      this.bathShow = true
      this.$refs.Xz.clickInit()
    },
    Jl() {
      this.$refs.Jl.clickInit()
      this.feedShow = true
    },
    Da() {
      this.$refs.Da.clickInit()
      this.fileShow = true
    },
  },
}
</script>

<style lang="scss" scoped>
.van-nav-bar {
  height: 1.7rem;
  background: #d79f6d;
  /deep/ .van-nav-bar__content {
    height: 100%;
    font-size: 0.5rem;
  }
  /deep/ .van-nav-bar__title {
    font-size: 0.7rem;
    font-weight: 700;
    color: #fff;
    height: 1.4rem;
    line-height: 1.4rem;
    -webkit-text-stroke: 1px #945614;
  }
}

.navImg {
  width: 0.9rem;
  height: 0.9rem;
  margin-right: 0.3rem;
}
.content {
  height: 100vh;
  width: 100vw;
  background: #fbe5ba;
  .bar {
    width: 8.7rem;
    height: 2rem;
    margin: auto;
    margin-top: 0.5rem;
    background: url('../../assets/img/feed/tnogzhi.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 100%;
    display: flex;
    align-items: center;
    img {
      width: 0.7rem;
      height: auto;
      margin-left: 0.28rem;
    }
    .van-notice-bar {
      width: 7rem;
      border-radius: 1rem;
      margin: auto;
      margin-right: 0.3rem;
      /deep/ .van-notice-bar__content {
        font-size: 0.39rem;
      }
    }
  }
  .info {
    width: 100%;
    height: 11rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    margin-top: 0.2rem;
    .video {
      width: 8rem;
      height: 6.9rem;
      background: #000;
      overflow: hidden;
      /deep/ .videos {
        width: 8rem;
        height: 6.9rem;
      }
    }
    .zl {
      position: absolute;
      top: 0rem;
      left: 0.3rem;
    }
    .qs {
      position: absolute;
      top: 2rem;
      left: 0.3rem;
    }
    .xz {
      position: absolute;
      top: 4.2rem;
      left: 0.3rem;
    }
    .bk {
      position: absolute;
      top: 0rem;
      right: 0.3rem;
    }
    .da {
      position: absolute;
      top: 2rem;
      right: 0.3rem;
    }
    .fx {
      position: absolute;
      top: 4.2rem;
      right: 0.3rem;
    }
    .zl,
    .qs,
    .xz,
    .bk,
    .da,
    .fx {
      img {
        width: 1.3rem;
        height: auto;
      }
    }
    .btn {
      height: 2rem;
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 0.5rem;
      position: relative;
      .isFeed {
        display: flex;
        align-items: center;
        margin-right: 0.3rem;
        img {
          width: 5rem;
          height: auto;
        }
      }
      .yijing {
        width: 4.5rem;
        height: 2rem;
        background: url('../../assets/img/feed/yijing.png');
        background-position: 100%;
        background-size: 100%;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          font-size: 0.5rem;
          font-weight: 600;
          color: #fff;
        }
      }
      .jilu {
        position: absolute;
        right: 0.4rem;
        top: 0.2rem;
        img {
          width: 1.6rem;
          height: auto;
        }
      }
    }
    .text {
      font-size: 0.4rem;
      font-weight: 400;
      color: #945513;
      margin-top: 0.35rem;
      display: flex;
      align-items: center;
      // span {
      //   color: #fff;
      // }
      span:not(:last-child)::after {
        content: '/';
      }
    }
  }
  .dongtai {
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .new {
      width: 8.7rem;
      height: 2rem;
      background: #fff;
      border-radius: 0.2rem;
      .text {
        color: #333333;
        font-size: 0.4rem;
        padding: 0.2rem 0.4rem;
        border-bottom: 1px solid #cccccc;
      }
      .newinfo {
        height: 1.1rem;
        display: flex;
        align-items: center;
        padding: 0 0.4rem;
        color: #333333;
        font-size: 0.4rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .newBtn {
          padding: 0.1rem 0.4rem;
          background: #f9750c;
          border-radius: 2rem;
          color: #fff;
        }
      }
      .friend {
        height: 1.1rem;
        display: flex;
        align-items: center;
        padding: 0 0.4rem;
        img {
          width: 0.85rem;
          height: 0.85rem;
          border-radius: 50%;
          overflow: hidden;
        }
        p {
          font-size: 0.4rem;
          font-weight: 500;
          color: #333333;
          padding-left: 0.3rem;
        }
      }
    }
  }
}
.sucess {
  width: 6rem;
  height: 7rem;
  background: #fbe5ba !important;
  border-radius: 0.2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  img {
    width: 1.8rem;
    height: 1.8rem;
  }
  .text {
    font-size: 0.5rem;
    font-weight: 600;
    color: #945614;
    margin-bottom: 0.2rem;
  }
  .textinfo {
    font-size: 0.38rem;
    font-weight: 400;
    color: #945614;
  }
  .zhi {
    font-size: 0.4rem;
    font-weight: 500;
    color: #ffffff;
    padding: 0.2rem 0.7rem;
    border-radius: 3rem;
    background: #945614;
    margin-top: 0.6rem;
  }
}
.error {
  width: 6rem;
  height: 5rem;
  background: #fbe5ba !important;
  border-radius: 0.2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .text {
    font-size: 0.6rem;
    font-weight: 600;
    color: #945614;
    margin-bottom: 0.2rem;
  }
  .textinfo {
    font-size: 0.4rem;
    font-weight: 400;
    color: #945614;
  }
  .textinfo:last-child {
    margin-top: 0.2rem;
  }
  .zhi {
    font-size: 0.4rem;
    font-weight: 500;
    color: #ffffff;
    padding: 0.2rem 0.7rem;
    border-radius: 3rem;
    background: #945614;
    margin-top: 0.6rem;
  }
}
.readfeed {
  font-size: 0.55rem;
  font-weight: 600;
  color: #fff;
}
.van-popup {
  background: none;
}
.treat {
  width: 9rem;
  height: 6rem;
  background: none;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .treatbox {
    width: 8rem;
    height: 5rem;
    background: #fbe5ba;
    border-radius: 0.2rem;
    overflow: hidden;
    .heard {
      height: 1.3rem;
      width: 100%;
      background: #d79f6d;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      width: auto;
      height: 0.75rem;
    }
    .zanwu {
      width: 100%;
      height: 7em;
      font-size: 0.5rem;
      font-weight: 600;
      color: #945614;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .close {
    position: absolute;
    top: 0.1rem;
    right: 0.2rem;
    img {
      width: 1rem;
      height: auto;
    }
  }
}

.erroeShow {
  width: 5rem;
  min-height: 2rem;
  background: #fbe5ba;
  border-radius: 0.2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 0 0.1rem #cccccc;
  .text {
    font-size: 0.5rem;
    font-weight: 600;
    color: #945614;
    // margin-bottom: 0.4rem;
  }
  .textinfo {
    width: 4rem;
    font-size: 0.35rem;
    font-weight: 400;
    color: #945614;
    text-align: center;
  }
}

.van-popup {
  background: none;
}
.yichang {
  width: 5rem;
  height: 5.6rem;
  background: #fde3bc;
  border-radius: 0.2rem;
  overflow: hidden;
  .title {
    font-size: 0.57rem;
    color: #945614;
    text-align: center;
    margin-top: 0.6rem;
  }
  .info {
    font-size: 0.4rem;
    color: #945614;
    margin-top: 0.5rem;
    text-align: center;
    p {
      margin-top: 0.1rem;
    }
  }
  .kown {
    width: 3rem;
    height: 0.87rem;
    background: #945614;
    color: #fff;
    margin: auto;
    margin-top: 0.8rem;
    line-height: 0.86rem;
    text-align: center;
    border-radius: 1rem;
    font-size: 0.4rem;
  }
}
</style>
