<template>
  <div>
    <van-popup v-model="wsShow">
      <div class="content">
        <div class="contentinfo">
          <van-tabs v-model="active" type="card">
            <van-tab title="食物">
              <div class="foodsall">
                <van-grid
                  :column-num="3"
                  :border="false"
                  :gutter="10"
                  v-if="!!foodList"
                >
                  <van-grid-item
                    v-for="(item, index) in foodList"
                    :key="item.pro_id"
                    class="bath"
                  >
                    <p class="putong" v-if="item.cate_id == 1">
                      {{ item.pro_name + item.weight + "份" }}
                    </p>
                    <p class="putong" v-else>
                      {{ item.pro_name + item.weight + "g" }}
                    </p>
                    <div class="imgDiv">
                      <van-badge :content="item.left_copies">
                        <img :src="item.image" class="imgs" alt="" />
                      </van-badge>
                    </div>
                    <!-- 使用 -->
                    <div class="shiyong" @click="Use(item, index)"></div>
                  </van-grid-item>
                </van-grid>
                <div class="nothing" v-else>
                  <img
                    src="../../../assets/img/feed/kong.png"
                    class="kong"
                    alt=""
                  />
                  <p class="te">这里面空空如也~</p>
                  <p class="te">快去商店购买吧！</p>
                  <img
                    src="../../../assets/img/feed/go.png"
                    class="go"
                    @click="goBuy"
                  />
                </div>
              </div>
            </van-tab>
            <van-tab title="套餐">
              <div class="taocan" v-if="!!mealList">
                <div class="food" v-for="item in mealList" :key="item.id">
                  <p>{{ item.name }}</p>
                  <div class="boss">
                    <div class="leftbox">
                      <div
                        class="box"
                        v-for="(val, index) in item.feed"
                        :key="index"
                      >
                        <img :src="val.image" alt="" />
                        <p class="text">{{ val.name }}</p>
                      </div>
                    </div>
                    <div class="rightbox">
                      <div class="ws">
                        <img
                          src="../../../assets/img/feed/ws1.png"
                          alt=""
                          :class="{ dontClick: isClick }"
                          @click="mealFeed(item.id)"
                        />
                        <p class="shenyu">剩余{{ item.meal_copies }}份</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nothing" v-else>
                <img
                  src="../../../assets/img/feed/kong.png"
                  class="kong"
                  alt=""
                />
                <p class="te">这里面空空如也~</p>
                <p class="te">快去商店购买吧！</p>
                <img
                  src="../../../assets/img/feed/go.png"
                  class="go"
                  @click="goBuy"
                />
              </div>
            </van-tab>
          </van-tabs>
        </div>
        <div class="close" @click="close">
          <img src="../../../assets/img/close.png" alt="" />
        </div>
      </div>
      <div class="bottom" v-if="active == 0">
        <div class="select">
          <van-grid :column-num="4" :gutter="5" :border="false">
            <van-grid-item
              v-for="(value, index) in select"
              :key="value.pro_id"
              class="yixuan"
            >
              <div class="imgDiv" @click="remove(value, index)">
                <van-badge :content="value.left_copies">
                  <img :src="value.image" class="imgs" alt="" />
                </van-badge>
                <p class="selectinfo">{{ value.pro_name }}</p>
              </div>
            </van-grid-item>
          </van-grid>
          <p class="title" :class="{ jitter: jitter }" v-if="foods.shui">
            {{ foods.msg }}
          </p>
        </div>
        <!-- 去喂食 -->
        <div class="go" @click="goFeed" :class="{ dontClick: isClick }"></div>
      </div>
    </van-popup>
    <van-popup v-model="erroeShow" :overlay="false" class="erroeShow">
      <p class="text">温馨提示</p>
      <p class="textinfo">{{ message }}</p>
    </van-popup>
  </div>
</template>

<script>
import { feedFood, feedMeal, feed } from "../../../common/api";
export default {
  props: {
    wsShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active: 0,
      show: true,
      foodList: [],
      mealList: [],
      select: [],
      isClick: false,
      erroeShow: false,
      message: "",
      timeout: 2000, //展示时间
      jitter: true, // 抖动
      foods: {
        shui: false,
        msg: "",
      },
    };
  },
  created() {},
  methods: {
    clickInit() {
      this.food();
    },
    async mealFeed(id) {
      this.isClick = true;
      let val = {
        access_id: this.$store.state.access_id,
        ls_id: this.$store.state.p_id,
        meal_id: id,
      };
      const res = await feed(val);
      console.log(res.data.code, "resres");
      if (res.data.code == 200) {
        this.$emit("success");
        this.isClick = false;
      } else {
        this.message = res.data.message;
        this.erroeShow = !this.erroeShow;
        this.isClick = false;
        setTimeout(() => {
          this.erroeShow = !this.erroeShow;
        }, this.timeout);
      }
    },
    //食物列表
    async food() {
      let data = {
        access_id: this.$store.state.access_id,
        ls_id: this.$store.state.p_id,
        type: "1",
      };
      let res = await feedFood(data);
      if (res.data.code == "40004") {
        this.foodList = res.data.data;
      }
      if (res.data.code == "200") {
        this.foodList = res.data.data;
      }
      let data1 = {
        access_id: this.$store.state.access_id,
        ls_id: this.$store.state.p_id,
      };
      let meal = await feedMeal(data1);
      if (meal.data.code == "40004") {
        this.mealList = meal.data.data;
      }
      if (meal.data.code == "200") {
        this.mealList = meal.data.data;
      }
    },
    // 去喂食
    async goFeed() {
      this.isClick = true;
      let food = this.select.map((res) => {
        return res.pro_id + ":" + res.left_copies;
      });
      let pro = food.join("#");
      let data = {
        access_id: this.$store.state.access_id,
        ls_id: this.$store.state.p_id,
        pro_id: pro,
      };
      const res = await feed(data);
      console.log(res, "resres");
      if (res.data.code == "70003" || res.data.code == "70004") {
        this.foods.shui = true;
        this.foods.msg = res.data.message;
        this.jitter = true;
        this.isClick = false;
        setTimeout(() => {
          this.jitter = false;
        }, 600);
        return;
      }
      if (res.data.code == "800001") {
        this.$emit("error");
        this.isClick = false;
        return;
      }
      if (res.data.code == 200) {
        this.select = [];
        this.$emit("success");
        this.isClick = false;
      } else {
        this.message = res.data.message;
        this.erroeShow = !this.erroeShow;
        this.isClick = false;
        setTimeout(() => {
          this.erroeShow = !this.erroeShow;
        }, this.timeout);
      }
    },
    // 使用
    Use(data, val) {
      if (data.cate_id == 1 || data.cate_id == 4) {
        this.foods.shui = false;
      }
      if (this.foodList[val].left_copies > 0) {
        this.foodList[val].left_copies -= 1;
        if (this.select.length == 0) {
          this.select.push(JSON.parse(JSON.stringify(data)));
          this.select[0].left_copies = 1;
        } else {
          let proid = this.select.findIndex((item) => {
            return item.pro_id == data.pro_id;
          });
          if (proid == -1) {
            if (this.select.length == 4) {
              this.message = "只能选四种食物哦~";
              this.erroeShow = !this.erroeShow;
              setTimeout(() => {
                this.erroeShow = !this.erroeShow;
              }, this.timeout);
            } else {
              this.select.push(JSON.parse(JSON.stringify(data)));
              this.select[this.select.length - 1].left_copies = 1;
            }
          } else {
            this.select[proid].left_copies++;
          }
        }
      } else {
        this.message = "已空，请选择别的食物";
        this.erroeShow = !this.erroeShow;
        setTimeout(() => {
          this.erroeShow = !this.erroeShow;
        }, this.timeout);
      }
    },
    //删除
    remove(val, index) {
      console.log(val);
      this.foodList.forEach((res) => {
        if (val.pro_id == res.pro_id) {
          res.left_copies += val.left_copies;
        }
      });
      this.select.splice(index, 1);
    },
    goBuy() {
      console.log("去购买");
      window.location.href = "uniwebview://goBuy?test=goBuy";
    },
    close() {
      this.$emit("closeWs", false);
      this.select = [];
      this.foods.shui = false;
      this.food();
    },
  },
};
</script>

<style lang="scss" scoped>
.van-popup {
  width: 100vw;
  // height: 100vh;
  background: none;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .bottom {
    height: 5rem;
    width: 8.9rem;
    background: url("../../../assets/img/feed/select.png");
    background-position: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    .select {
      width: 7.8rem;
      height: 2.4rem;
      margin-top: 0.7rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .title {
        font-size: 0.3rem;
        color: #ff0000;
        padding-left: 0.3rem;
      }
      .jitter {
        -webkit-animation: shake 0.2s infinite;
        animation: shake 0.2s infinite;
      }
      .van-grid {
        width: 100%;
        height: 1.85rem;
        padding-left: 6px !important;
        margin-top: 0.1rem;
        /deep/ .van-grid-item__content--center {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          padding: 0;
          background: none;
        }
      }
      .yixuan {
        width: 1rem;
        height: 2rem;
        position: relative;
        /deep/ .van-badge {
          font-size: 0.37rem;
        }
      }
      .imgDiv {
        position: relative;
        img {
          width: 1.4rem;
          height: 1.4rem;
          border-radius: 0.2rem;
        }
        .selectinfo {
          font-size: 0.3rem;
          text-align: center;
          color: #945614;
        }
      }
    }
    .go {
      width: 4rem;
      height: 1.5rem;
      background: url("../../../assets/img/feed/gows.png");
      background-position: 100%;
      background-size: 100%;
      background-repeat: no-repeat;
    }
  }
  .content {
    height: 11.2rem;
    width: 8.9rem;
    background: url("../../../assets/img/feed/ws.png");
    background-position: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    /deep/ .van-tabs__content {
      margin-top: 0.8rem;
    }
    .contentinfo {
      height: 9rem;
      width: 7.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 1.514rem;
      overflow: hidden;
      overflow-y: scroll;
      .nothing {
        height: 5.7rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .kong {
          width: 4rem;
          height: auto;
          margin-bottom: 0.3rem;
        }
        .te {
          font-size: 0.3rem;
          color: #945614;
        }
        .go {
          width: 3rem;
          height: auto;
          margin-top: 0.2rem;
        }
      }
      .van-tabs {
        margin-top: 0.099rem;
        .infoText {
          height: 2.75rem;
          padding: 0 0.2rem;
          overflow: hidden;
          overflow-y: scroll;
          margin-top: 0.1rem;
          display: flex;
          .content {
            height: 0.88rem;
            background: #fbe5ba;
            border-radius: 0.25rem;
            padding: 0.2rem 0.3rem;
            margin: 0.15rem 0;
            display: flex;
            flex-direction: column;
            .time {
              font-size: 0.3rem;
              color: #945614;
              line-height: 1;
              font-weight: 400;
              margin-top: 0.1rem;
            }
            .medicine {
              font-size: 0.35rem;
              color: #945614;
              font-weight: 600;
              line-height: 2;
            }
          }
        }
        .van-grid {
          width: 7rem;
          padding-left: 6px !important;
          margin: 0.2rem 0;
          margin-left: 0.2rem;
          /deep/ .van-grid-item__content--center {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding: 0;
          }
          /deep/ .van-badge {
            font-size: 0.37rem;
          }
          .bath {
            width: 1rem;
            height: 3.23rem;
            overflow: hidden;
            position: relative;
            p {
              margin: 0;
              padding: 0;
            }
            .putong {
              height: 0.86rem;
              width: 100%;
              font-size: 0.25rem;
              text-align: center;
              line-height: 0.86rem;
              background: #945614;
              color: #fff;
            }
          }
          .imgDiv {
            position: absolute;
            top: 0.7rem;
          }
          .shiyong {
            width: 1.6rem;
            height: 1rem;
            margin-top: 1.5rem;
            background: url("../../../assets/img/feed/use.png");
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: 100%;
          }
          .imgs {
            width: 1.67rem;
            height: 1.67rem;
            border-radius: 0.2rem;
          }
        }
        .food {
          width: 6.5rem;
          min-height: 2.5rem;
          background: #fbe5ba;
          border-radius: 0.3rem;
          border: 1px solid #945614;
          padding: 0.2rem;
          margin-top: 0.25rem;
          p {
            font-size: 0.35rem;
            color: #945614;
          }
          .boss {
            width: 100%;
            height: 1rem;
            display: flex;
            .leftbox {
              width: 4.5rem;
              height: 1.9rem;
              margin-top: 0.15rem;
              display: flex;
              overflow: hidden;
              overflow-x: scroll;
              padding-left: 0.18rem;
              .box {
                width: 1.4rem;
                margin-right: 0.18rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                img {
                  width: 1.4rem;
                  height: 1.4rem;
                  border-radius: 0.2rem;
                }
                .text {
                  font-size: 0.39rem;
                  text-align: center;
                }
              }
            }
            .rightbox {
              width: 2rem;
              height: 1.8rem;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              .ws {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                img {
                  width: 1.5rem;
                  height: auto;
                }
                .shenyu {
                  font-size: 0.39rem;
                }
              }
            }
          }
        }
        /deep/ .van-tabs__content {
          width: 7rem;
          overflow: hidden;
          overflow-y: scroll;
        }
        /deep/ .van-tabs__wrap {
          height: 0.8rem;
        }
        /deep/ .van-tabs__nav--card {
          height: 0.8rem;
          line-height: 0.8rem;
          border: none;
          margin: 0;
        }
        /deep/ .van-tab {
          width: 3rem;
          border-radius: 0.4rem 0.4rem 0 0;
          margin: 0 0.2rem;
          color: #945614;
          border: none;
          background: #d48f50;
          font-size: 0.45rem;
        }
        /deep/ .van-tab--active {
          border: none;
          background: #fbe5ba;
        }
        /deep/ .van-tab__text {
          height: 0.8rem;
          line-height: 0.8rem;
        }
        /deep/ .van-tabs__nav {
          width: 7.5rem;
          position: fixed;
          background-color: #d79f6d;
          padding-right: 0.3rem;
          // top: 1.626rem;
        }
      }
    }
  }
}
.close {
  position: absolute;
  top: 0.65rem;
  right: -0.1rem;
  img {
    width: 1rem;
    height: auto;
  }
}
::-webkit-scrollbar {
  display: none;
}

.erroeShow {
  width: 5rem;
  min-height: 2rem;
  background: #fbe5ba;
  border-radius: 0.2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 0 0.1rem #cccccc;
  .text {
    font-size: 0.5rem;
    font-weight: 600;
    color: #945614;
    // margin-bottom: 0.4rem;
  }
  .textinfo {
    width: 4rem;
    font-size: 0.35rem;
    font-weight: 400;
    color: #945614;
    text-align: center;
  }
}
@-webkit-keyframes shake {
  0% {
    -webkit-transform: translate(2px, 2px);
  }
  25% {
    -webkit-transform: translate(-2px, -2px);
  }
  50% {
    -webkit-transform: translate(0px, 0px);
  }
  75% {
    -webkit-transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(-2px, 2px);
  }
}
@keyframes shake {
  0% {
    transform: translate(2px, 2px);
  }
  25% {
    transform: translate(-2px, -2px);
  }
  50% {
    transform: translate(0px, 0px);
  }
  75% {
    transform: translate(2px, -2px);
  }
  100% {
    transform: translate(-2px, 2px);
  }
}
.foodsall {
  height: 6.7rem;
  width: 7.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  overflow-y: scroll;
  /deep/ .van-grid-item__content {
    background: #fbe5ba;
  }
}
.taocan {
  height: 6.4rem;
  // widt h: 7.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  overflow-y: scroll;
}
</style>
