<template>
  <div>
    <van-popup v-model="feedShow">
      <div class="content">
        <div class="contentinfo" v-if="feedList">
          <div class="div-content" v-for="item in feedList" :key="item[0].date">
            <div>
              <p class="time">{{ item[0].date }}</p>
              <div class="info" v-for="dd in item" :key="dd.id">
                <div class="info_left">
                  <p class="info_time">{{ dd.time }}</p>
                  <div class="info_text" v-for="val in dd.feed" :key="val.id">
                    <p
                      v-if="
                        val.cate_id != 2 && val.cate_id != 1 && val.cate_id != 3
                      "
                    >
                      {{ val.name + "：" + val.weight + "克" }}
                    </p>
                    <p v-if="val.cate_id == 1">
                      {{ val.name + "：" + val.weight + "份" }}
                    </p>
                    <p v-if="val.cate_id == 2">
                      {{ val.name }}
                    </p>
                    <p v-if="val.cate_id == 3">
                      {{ val.name }}
                    </p>
                  </div>
                </div>
                <div class="info_right" v-if="dd.video_url">
                  <p @click="lookLu(dd.video_url)">查看录屏</p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="isNull">
            <div class="none">暂无数据</div>
          </div>
        </div>
        <p class="lookMore" v-if="!isNull" @click="$router.push('/more')">
          查看更多
        </p>
        <div class="close" @click="close">
          <img src="../../../assets/img/close.png" alt="" />
        </div>
      </div>
    </van-popup>
    <luxiang
      :lushow="lushow"
      v-if="lushow"
      :url="video_url"
      @closeLu="closeLu"
    ></luxiang>
  </div>
</template>

<script>
import luxiang from "../components/luxiang.vue";
import { record } from "../../../common/api";
export default {
  components: {
    luxiang,
  },
  props: {
    feedShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      feedList: [],
      lushow: false,
      isNull: false,
      video_url: "",
    };
  },

  methods: {
    clickInit() {
      this.recordJl();
    },
    lookLu(url) {
      this.video_url = url;
      this.lushow = true;
    },
    //喂养记录
    async recordJl() {
      let data = {
        access_id: this.$store.state.access_id,
        ls_id: this.$store.state.p_id,
        page: 1,
        days: 3,
      };
      let res = await record(data);
      console.log(res, "喂养记录");
      if (res.data.code == "4004") {
        this.isNull = true;
        return;
      }
      if (res.data.code != 200) return;
      this.feedList = res.data.data;
    },
    close() {
      this.$emit("closeJl", false);
    },
    closeLu(val) {
      this.lushow = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.van-popup {
  width: 100vw;
  height: 100vh;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    height: 17rem;
    width: 8.9rem;
    background: url("../../../assets/img/feed/feed.png");
    background-position: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    .contentinfo {
      height: 12rem;
      width: 7.9rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 2.6rem;
      overflow: hidden;
      overflow-y: scroll;
      .row {
        margin-top: 0.2rem;
      }
      .time {
        width: 100%;
        font-size: 0.6rem;
        font-weight: 600;
        color: #945614;
        // padding-top: 0.3rem;
      }

      .all {
        width: 100%;
        font-size: 0.4rem;
        color: #945614;
        padding-top: 0.1rem;
      }
      .info {
        width: 7.6rem;
        background: #000;
        margin-top: 0.2rem;
        background: rgba(#945614, 0.1);
        display: flex;
        justify-content: space-between;
        align-items: center;
        .info_left {
          width: 60%;
          height: 100%;
          .info_time {
            font-size: 0.3rem;
            color: #945614;
            padding: 0.1rem 0 0.1rem 0.3rem;
          }
          .info_text {
            font-size: 0.4rem;
            color: #945614;
            padding-left: 0.3rem;
            padding-bottom: 0.1rem;
            font-weight: 600;
          }
        }
        .info_right {
          p {
            padding: 0.1rem 0.34rem;
            background: #fbe5ba;
            margin-right: 0.3rem;
            border-radius: 4rem;
            color: #945614;
            border: 1px solid #945614;
          }
        }
      }
    }
  }
}
.close {
  position: absolute;
  top: 1.4rem;
  right: -0.1rem;
  img {
    width: 1rem;
    height: auto;
  }
}
.lookMore {
  margin-top: 0.3rem;
  font-size: 0.4rem;
  color: #945614;
}
::-webkit-scrollbar {
  display: none;
}
.time {
  width: 100%;
  font-size: 0.6rem;
  font-weight: 600;
  color: #945614;
  padding: 0.3rem 0 0 0.15rem;
}
.info {
  width: 9rem;
  // height: 2rem;
  background: #000;
  margin-top: 0.2rem;
  background: rgba(#945614, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.2rem;
  .info_left {
    width: 70%;
    height: 100%;
    .info_time {
      font-size: 0.35rem;
      color: #945614;
      padding-left: 0.3rem;
      padding-top: 0.15rem;
    }
    .info_text {
      font-size: 0.4rem;
      color: #945614;
      padding-left: 0.3rem;
      padding-top: 0.1rem;
      font-weight: 600;
    }
    .info_text:last-child {
      padding-bottom: 0.2rem;
    }
  }
  .info_right {
    p {
      padding: 0.1rem 0.34rem;
      background: #fbe5ba;
      margin-right: 0.3rem;
      border-radius: 4rem;
      color: #945614;
      border: 1px solid #945614;
    }
  }
}
.none {
  width: 100%;
  height: 3.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.4rem;
  color: #945614;
  margin-top: 4rem;
}
</style>
