<template>
  <div>
    <van-popup v-model="bathShow">
      <div class="content">
        <div class="contentinfo">
          <van-grid
            :column-num="3"
            :border="false"
            :gutter="10"
            v-if="!!bathList"
          >
            <van-grid-item
              v-for="item in bathList"
              :key="item.pro_id"
              class="bath"
            >
              <p class="putong">{{ item.pro_name }}</p>
              <div class="imgDiv">
                <van-badge :content="item.left_copies">
                  <img :src="item.image" class="imgs" alt="" />
                </van-badge>
              </div>
              <!-- 使用 -->
              <div
                class="shiyong"
                :class="{ dontClick: isClick }"
                @click="Use(item.pro_id, item.pro_name)"
              ></div>
            </van-grid-item>
          </van-grid>
          <div class="nothing" v-else>
            <img src="../../../assets/img/feed/kong.png" class="kong" alt="" />
            <p class="te">这里面空空如也~</p>
            <p class="te">快去商店购买吧！</p>
            <img
              src="../../../assets/img/feed/go.png"
              class="go"
              @click="goBuy"
            />
          </div>
        </div>
        <div class="close" @click="close">
          <img src="../../../assets/img/close.png" alt="" />
        </div>
      </div>
    </van-popup>
    <van-popup
      v-model="sucessShow"
      class="sucess"
      :close-on-click-overlay="false"
    >
      <img src="../../../assets/img/dui.png" alt="" />
      <p class="text">申请成功</p>
      <p class="textinfo">待饲养员给猪{{ name }}，</p>
      <p class="textinfo">稍后可在消息中查看通知！</p>
      <p class="zhi" @click="knowledge">我知道了</p>
    </van-popup>
  </div>
</template>

<script>
import { feedFood, wash } from "../../../common/api";

export default {
  props: {
    bathShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeName: "a",
      condition: false,
      bathList: [],
      sucessShow: false,
      isClick: false,
      name: "",
    };
  },
  methods: {
    clickInit() {
      this.bath();
    },
    async Use(id, name) {
      this.isClick = true;
      let data = {
        access_id: this.$store.state.access_id,
        ls_id: this.$store.state.p_id,
        pro_id: id,
      };
      const res = await wash(data);
      if (res.data.code != 200) {
        this.isClick = false;
        this.$toast(res.data.message);
        return;
      }
      this.sucessShow = !this.sucessShow;
      this.isClick = false;
      this.name = name;
    },
    knowledge() {
      this.sucessShow = !this.sucessShow;
      this.close();
    },
    close() {
      this.$emit("closeXz", false);
      this.bath();
    },
    goBuy() {
      console.log("去购买");
      window.location.href = "uniwebview://goBuy?test=goBuy";
    },
    //洗澡列表
    async bath() {
      let data = {
        access_id: this.$store.state.access_id,
        ls_id: this.$store.state.p_id,
        type: "4",
      };
      let res = await feedFood(data);
      if (res.data.code == "40004") {
        this.bathList = res.data.data;
        console.log(this.bathList, " this.bathList ");
      }
      if (res.data.code == "200") {
        this.bathList = res.data.data;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.van-popup {
  width: 100vw;
  height: 100vh;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    height: 11rem;
    width: 8.9rem;
    background: url("../../../assets/img/feed/bath.png");
    background-position: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    .contentinfo {
      height: 6.8rem;
      width: 7rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 2.55rem;
      overflow: hidden;
      overflow-y: scroll;
      .van-grid {
        width: 100%;
        /deep/ .van-grid-item__content--center {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          padding: 0;
        }
        /deep/ .van-badge {
          font-size: 0.37rem;
        }
        .bath {
          width: 1rem;
          height: 3.23rem;
          overflow: hidden;
          position: relative;
          p {
            margin: 0;
            padding: 0;
          }
          .putong {
            height: 0.86rem;
            width: 100%;
            text-align: center;
            line-height: 0.86rem;
            background: #945614;
            color: #ffffff;
          }
        }
        .imgDiv {
          position: absolute;
          top: 0.7rem;
        }
        .shiyong {
          width: 1.6rem;
          height: 1rem;
          margin-top: 1.5rem;
          background: url("../../../assets/img/feed/use.png");
          background-repeat: no-repeat;
          background-size: 100%;
          background-position: 100%;
        }
        .imgs {
          width: 1.67rem;
          height: 1.67rem;
          border-radius: 0.2rem;
        }
      }
    }
  }
}
.close {
  position: absolute;
  top: 1.2rem;
  right: -0.2rem;
  img {
    width: 1rem;
    height: auto;
  }
}
.nothing {
  height: 7.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .kong {
    width: 4rem;
    height: auto;
    margin-bottom: 0.3rem;
  }
  .te {
    font-size: 0.3rem;
    color: #945614;
  }
  .go {
    width: 3rem;
    height: auto;
    margin-top: 0.2rem;
  }
}
::-webkit-scrollbar {
  display: none;
}
.sucess {
  width: 6rem;
  height: 7rem;
  background: #fbe5ba;
  border-radius: 0.2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  img {
    width: 1.8rem;
    height: 1.8rem;
  }
  .text {
    font-size: 0.5rem;
    font-weight: 600;
    color: #945614;
    margin-bottom: 0.2rem;
  }
  .textinfo {
    font-size: 0.38rem;
    font-weight: 400;
    color: #945614;
  }
  .zhi {
    font-size: 0.4rem;
    font-weight: 500;
    color: #ffffff;
    padding: 0.2rem 0.7rem;
    border-radius: 3rem;
    background: #945614;
    margin-top: 0.6rem;
  }
}
.dontClick {
  pointer-events: none;
}
</style>
